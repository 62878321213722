<template>
  <div class="commbox" id="top">
    <CompMailHeader />
    <div class="center" > 
      <div class="changetypebox">
        <div class="typeitem" @click="showsearch">
          {{ typename }}
          <div class="triangle-down"></div>
        </div>
        <div class="typeitem" @click="sortclick">
          价格
          <div class="triangle-down" v-show="sort == 1"></div>
          <div class="triangle-up" v-show="sort == 0"></div>
        </div>
        <div
          class="typeitem"
          @click="exchangeclick"
          :class="{ actypeitem: exchange == 1 }"
        >
          可兑换
        </div>
        <div class="searchlistbox" v-show="issearch">
          <div
            class="searchitem"
            v-for="item in searchList"
            :key="item.id"
            :class="{ acsearchitem: item.id == searchindex }"
            @click="searchclick(item.id, item.name)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="searchbox" id="top">
        <input
          type="text"
          placeholder="请输入想要搜索的商品"
          v-model="searchvalue"
        />
        <div class="searchenter" @click="searchvuleclick">
          <img src="../../assets/newImages/search-icon.png" alt="" />
        </div>
      </div>

      <div class="listbox" >
        <div
          class="goodsitem"
          v-for="item in goodsvalue"
          :key="item.id"
          @click.stop="tocheck(item)"
        >
          <div class="cintbox">
            <CompCoin imgName="coin-z" :num="item.mall_bean" />
          </div>
          <div class="goodsimg">
            <img v-lazy="item.cover" alt="" />
          </div>
          <div class="goodsname">
            {{ item.name }}
          </div>
          <div class="goodsdur">
            {{ item.dura_alias }}
          </div>

          <div class="ControlBox" v-show="chekindex.includes(item.id)">
            <div class="footerbox">
              <div class="lbox" @click.stop="subclick(item)"></div>
              <div class="numbox">
                {{ getCartItemById(item.id)?.quantity || 0 }}
              </div>
              <div class="rbox" @click.stop="addclick(item)"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagebox">
        <Pagination
          :total="total"
          :page.sync="page_num"
          :limit.sync="page_size"
          @pagination="handleCurrentChange"
        />
      </div>
    </div>
    <div class="caritem">
      <div class="caricon">
        <div class="imgbox" @click="showcar">
          <img src="../../assets/newImages/caritem-icon.png" alt="" />
          <div class="tipsicon" v-show="cartItems.length > 0">
            {{ totalnum }}
          </div>
        </div>
        <p>
          <CompCoin
            :styleList="[0.12, 0.12, 0.12, 0.05]"
            imgName="coin-z"
            :num="totalPrice.toFixed(2)"
          />
        </p>
      </div>
      <div class="paybox">
        <div class="paybtn" @click="gobuy">购买</div>
      </div>
    </div>
    <div class="carlistbg" v-show="carshow">
      <div class="carlist">
        <div class="listtitle">
          <div class="lbox">已添加 {{ totalnum }} 件饰品</div>
          <div class="rbox" @click="removiecar">清空购物车</div>
        </div>
        <div class="carlistbox">
          <div class="carlistitem" v-for="item in cartItems" :key="item.id">
            <div class="lbox">
              <div class="goosimg">
                <img :src="item.img" alt="" />
              </div>
              <div class="goodsinfo">
                <div>{{ item.name }}</div>
                <div>{{ item.dura_alias }}</div>
                <div>
                  <CompCoin
                    imgName="coin-z"
                    :num="(item.price * item.quantity).toFixed(2)"
                  />
                </div>
              </div>
            </div>

            <div class="numbox">
              <div class="lbox" @click.stop="subclick(item)"></div>
              <div class="num">
                {{ getCartItemById(item.id)?.quantity || 0 }}
              </div>
              <div class="rbox" @click.stop="addclick(item)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Tabbar />
  </div>
</template>

<script>
import { GetShop, Shop_Buy, PersonalInfo, luckyType } from "@/network/api.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      searchList: [],
      searchindex: 0,
      issearch: false,
      searchvalue: null,
      typename: "全部", //选中名字
      goodsvalue: [], //商品列表

      chekindex: [], //选中物品

      cartItems: [], // 购物车，每个商品项包含 id, name, price, quantity
      newItemQuantity: 1,

      carshow: false,

      total: 0, //列表总数
      page_size: 24, //一页条数
      page_num: 1, //页码
      type: null, //物品类型
      sort: 1, //排序
      exchange: null,
    };
  },
  computed: {
    totalPrice() {
      return this.cartItems.reduce((total, item) => {
        return total + item.price * item.quantity;
      }, 0);
    },
    totalnum() {
      return this.cartItems.reduce((total, item) => {
        return total + item.quantity;
      }, 0);
    },
  },
  created() {
    this.Gettype();
    this.getgoodsdata();
  },
  methods: {
     scrollToTop() {
       // 将滚动盒子滚动到顶部
      document.getElementById('top').scrollIntoView({
        behavior: 'smooth' // 平滑滚动
    });
      
    },
    ...mapActions({
      MyInfo: "Refresh",
    }),
    //可兑换
    exchangeclick() {
      this.page_num = 1;
      if (this.exchange == 1) {
        this.exchange = null;
      } else {
        this.exchange = 1;
      }
      this.getgoodsdata();
    },
    sortclick() {
      this.page_num = 1;
      if (this.sort == 1) {
        this.sort = 0;
      } else {
        this.sort = 1;
      }
      this.getgoodsdata();
    },
    handleCurrentChange(v) {
      this.page_num = v.page;
      this.getgoodsdata();
      this.scrollToTop()
    },
    async gobuy() {
      let params = {
        in_ids: this.cartItems.map((v) => ({
          id: v.id,
          num: v.quantity,
        })),
      };
      const res = await Shop_Buy(params);
      if (res.data.code == 200) {
        this.$message.success(res.data.message);
        this.removiecar();
        this.MyInfo();
      } else {
        this.$message.error(res.data.message);
      }
    },
    removiecar() {
      this.chekindex = [];
      this.cartItems = [];
      if (this.carshow) {
        this.showcar();
      }
    },
    showcar() {
      if (this.carshow == true) {
        this.carshow = false;
        document.body.style.overflow = ""; // 启用底部页面滚动
      } else {
        this.carshow = true;
        document.body.style.overflow = "hidden"; // 禁用底部页面滚动
      }
    },
    subclick(id) {
      this.cartItems = this.cartItems
        .map((item) => {
          if (item.id == id.id) {
            if (item.quantity > 1) {
              item.quantity -= 1;
            } else {
              // 当数量为1时，移除该商品并取消选中
              this.tocheck(id);
              return null;
            }
          }
          return item;
        })
        .filter((item) => item !== null);
    },
    addclick(v) {
      const product = this.goodsvalue.find((p) => p.id === v.id);
      if (product) {
        let cartItem = this.cartItems.find((item) => item.id === product.id);
        if (!cartItem) {
          this.cartItems.push({
            id: product.id,
            name: product.name,
            price: product.mall_bean,
            quantity: Number(this.newItemQuantity),
            img: product.cover,
            dura_alias: product.dura_alias,
          });
        } else {
          cartItem.quantity += Number(this.newItemQuantity);
        }
        this.newItemQuantity = 1;
      }
    },

    tocheck(item) {
      const isChecked = this.chekindex.includes(item.id);
      if (isChecked) {
        // 如果已选中，则取消选中
        this.chekindex = this.chekindex.filter((i) => i !== item.id);
        //如果有数量移除
        this.cartItems = this.cartItems
          .map((cartItem) => {
            if (cartItem.id === item.id) {
              if (cartItem.quantity) {
                // 如果有数量，则移除该商品
                return null;
              }
            }
            return cartItem;
          })
          .filter((cartItem) => cartItem !== null);
      } else {
        // 如果未选中，则选中并添加到购物车
        this.chekindex.push(item.id);
        // 检查购物车中是否已存在该商品，如果不存在则添加，如果存在则增加数量
        const cartItem = this.cartItems.find(
          (cartItem) => cartItem.id === item.id
        );
        if (!cartItem) {
          this.cartItems.push({
            id: item.id,
            name: item.name,
            price: item.mall_bean,
            quantity: 1, // 初始数量为1
            // 可以选择性地添加其他属性，如 img
            img: item.cover,
            dura_alias: item.dura_alias,
          });
        } else {
          // 用户每次点击都是增加1个数量
          cartItem.quantity += 1;
        }
      }
    },
    getCartItemById(id) {
      return this.cartItems.find((item) => item.id === id);
    },

    async getgoodsdata() {
      let params = {
        name: this.searchvalue,
        type: this.type,
        sort: this.sort,
        page: this.page_num,
        exchange: this.exchange,
      };
      const res = await GetShop(params);
      if (res.data.code == 200) {
        this.goodsvalue = res.data.data.data;
        this.total = res.data.data.total;
        this.page_size = res.data.data.per_page;
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 搜索商品
    searchvuleclick() {
      if (this.searchvalue == null) {
        this.$message.error("请输入你想要搜索的物品。");
      } else if (this.searchvalue == "") {
        this.searchvalue = null;
        this.getgoodsdata();
      } else {
        this.getgoodsdata();
      }
    },
    showsearch() {
      this.issearch = !this.issearch;
    },
    // 分类切换
    searchclick(v, n) {
      this.typename = n;
      this.searchindex = v;
      this.page_num = 1;
      if (v == 0) {
        this.type = null;
      } else {
        this.type = v;
      }
      this.showsearch();
      this.getgoodsdata();
    },
    // 获得装备分类数据
    Gettype() {
      luckyType().then((res) => {
        let data = res.data.data;
        data.splice(6, 2);
        this.searchList = [
          {
            name: "全部",
            id: 0,
          },
        ];
        this.searchList.push(...data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.commbox {
  padding-top: 0.5rem;
}
.center {
  width: 3.35rem;
  margin: 0 auto;
  .changetypebox {
    width: 100%;
    height: 0.22rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.12rem 0;
    position: relative;
    .typeitem {
      width: 1.07rem;
      height: 0.22rem;
      @include bgImg("price-change-bg");
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 0.12rem;
      color: #ffffff;
      position: relative;
      .triangle-down {
        position: absolute;
        right: 0.1rem;
        width: 0;
        height: 0;
        border-left: 0.06rem solid transparent; /* 左边透明 */
        border-right: 0.06rem solid transparent; /* 右边透明 */
        border-top: 0.06rem solid #ffffff; /* 顶部颜色 */
      }
      .triangle-up {
        position: absolute;
        right: 0.1rem; /* 右边距 */
        width: 0;
        height: 0;
        border-left: 0.06rem solid transparent; /* 左边透明 */
        border-right: 0.06rem solid transparent; /* 右边透明 */
        border-bottom: 0.06rem solid #ffffff; /* 底部颜色（即三角形的颜色） */
      }
    }
    .actypeitem {
      @include bgImg("dust-mall-actypeitembg");
    }
    .searchlistbox {
      position: absolute;
      top: 0.25rem;
      right: -0.2rem;
      width: 3.75rem;
      height: 0.88rem;
      background: #1d1300;
      display: flex;
      flex-wrap: wrap;
      z-index: 3;
      padding: 0.05rem 0.15rem;
      .searchitem {
        padding: 0.05rem 0.18rem;
        background: rgba(63, 53, 33, 0.5);
        margin: 0.05rem 0.05rem 0.05rem 0.04rem;
        font-weight: 400;
        font-size: 0.12rem;
        color: #a89265;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .acsearchitem {
        background: rgba(255, 213, 129, 0.5);
        color: #ffffff;
      }
    }
  }
  .searchbox {
    display: flex;
    align-items: center;
    width: 100%;
    height: 0.22rem;
    input {
      width: 2.9rem;
      height: 100%;
      border: 0.01rem solid #817043;
      text-decoration: none;
      background: rgba(42, 27, 0, 0.6);
      text-indent: 0.28rem;
      color: #a4a4a4;
      font-size: 0.12rem;
    }
    .searchenter {
      width: 0.5rem;
      height: 0.22rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("../../assets/newImages/search-btn-bg.png") no-repeat;
      background-size: 100% 100%;
      img {
        width: 0.14rem;
        height: 0.14rem;
      }
    }
  }
  .listbox {
    margin-top: 0.07rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // height: 5.2rem;
    // overflow-y: scroll;
    .goodsitem {
      width: 1.07rem;
      height: 1.07rem;
      @include bgImg("box-item-bg");
      border: 0.01rem solid #69552d;
      margin-top: 0.07rem;
      position: relative;
      .cintbox {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 0.02rem;
      }
      .goodsimg {
        width: 0.8rem;
        height: 0.6rem;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
      .goodsname {
        font-weight: 400;
        font-size: 0.1rem;
        color: #ffffff;
        text-align: center;
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 隐藏超出部分 */
        text-overflow: ellipsis; /* 显示省略号 */
        padding: 0 0.05rem;
      }
      .goodsdur {
        font-weight: 400;
        font-size: 0.1rem;
        color: #ffffff;
        text-align: center;
        margin-top: 0.05rem;
      }
      .ControlBox {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1.07rem;
        height: 1.07rem;
        @include bgImg("battle-box-choosed");
        .footerbox {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 0.25rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.02rem;
          .lbox {
            width: 0.2rem;
            height: 0.2rem;
            @include bgImg("down-icon");
          }
          .rbox {
            width: 0.2rem;
            height: 0.2rem;
            @include bgImg("up-icon");
          }
          .numbox {
            font-weight: 500;
            font-size: 0.12rem;
            color: #040404;
          }
        }
      }
    }
  }
  .pagebox {
    margin-top: 0.12rem;
    padding-bottom: 1rem;
  }
}
.caritem {
  position: fixed;
  bottom: 0.7rem;
  width: 100%;
  height: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #4f4424;
  padding: 0 0.2rem;
  z-index: 1001;
  .caricon {
    display: flex;
    align-items: center;
    .imgbox {
      position: relative;
      img {
        width: 0.24rem;
        height: 0.24rem;
        margin-right: 0.15rem;
      }
      .tipsicon {
        position: absolute;
        width: 0.15rem;
        height: 0.15rem;
        font-size: 0.1rem;
        background: #ff6f00;
        border-radius: 50%;
        top: -0.1rem;
        right: 0.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .paybox {
    width: 1rem;
    height: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .paybtn {
      width: 0.95rem;
      height: 0.3rem;
      @include bgImg("search-btn-bg");
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 0.14rem;
      color: #000000;
    }
  }
}
.carlistbg {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  .carlist {
    width: 100%;
    height: 3.75rem;
    background: rgba(29, 19, 0, 1);
    position: absolute;
    bottom: 1.2rem;
    border-top: 0.02rem solid #ffd71d;
    left: 0;
    overflow-y: scroll;
    .listtitle {
      width: 3.35rem;
      margin: 0 auto;
      height: 0.6rem;
      display: flex;
      align-items: center;
      position: fixed;
      right: 0.2rem;
      background: rgba(29, 19, 0, 1);
      .lbox {
        width: 70%;
        font-weight: 500;
        font-size: 0.14rem;
        color: #ffffff;
      }
      .rbox {
        width: 30%;
        height: 0.28rem;
        background: #5c4a25;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 0.12rem;
        color: #e7b400;
      }
    }
    .carlistbox {
      width: 3.35rem;
      margin: 0.6rem auto 0;
      .carlistitem {
        width: 3.35rem;
        height: 0.68rem;
        @include bgImg("itemlistbg");
        border: 0.01rem solid #69552d;
        display: flex;
        align-items: center;
        margin-bottom: 0.08rem;
        padding: 0 0.1rem;
        .lbox {
          display: flex;
          align-items: center;
          .goosimg {
            width: 0.64rem;
            height: 0.48rem;
            margin-right: 0.1rem;
            img {
              width: 100%;
            }
          }
          .goodsinfo {
            width: 1.6rem;
            font-weight: 400;
            font-size: 0.1rem;
            color: #ffffff;
            div {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              white-space: nowrap;
              margin-bottom: 0.04rem;
              margin-top: 0.02rem;
            }
          }
        }

        .numbox {
          width: 0.9rem;
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 0.12rem;
          .lbox {
            width: 0.24rem;
            height: 0.24rem;
            @include bgImg("caritem-sub");
          }
          .rbox {
            width: 0.24rem;
            height: 0.24rem;
            @include bgImg("caritem-add");
          }
          .num {
            width: 0.15rem;
            font-weight: 500;
            font-size: 0.14rem;
            color: #ffffff;
            text-align: center;
            margin: 0 0.05rem;
          }
        }
      }
    }
  }
}
</style>
